import React from "react"

export default () => (
  <footer>
    <div className="social-icons">
      <a href="https://github.com/web-man/" target="_blank"><img src="../../github.svg" /></a>
      <a href="https://www.linkedin.com/in/igor-%C4%91uki%C4%87/" target="_blank"><img src="../../linkedin.svg" /></a>
      <a href="https://twitter.com/prodevl" target="_blank"><img src="../../twitter.svg" /></a>
    </div>
    <div className="copyright-text">
      <p>Copyright Igor Đukić {new Date().getFullYear()}  ©</p>
    </div>
  </footer>
)
